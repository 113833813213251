import Vue3linkify from "vue-3-linkify";

export default defineNuxtPlugin({
    name: 'linkify',
    // enforce: 'pre', // or 'post'
    async setup(nuxtApp) {
        // this is the equivalent of a normal functional plugin
        const linkify = Vue3linkify;
        nuxtApp.vueApp.use(Vue3linkify)
    }
})