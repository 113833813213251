import { default as aboutsV5eajZOMoMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/about.vue?macro=true";
import { default as addSpotwP7rqLoPrCMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/addSpot.vue?macro=true";
import { default as index8PDrEz3orJMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/entries/[oldId]/index.vue?macro=true";
import { default as index1m7c3KkmQSMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/entries/index.vue?macro=true";
import { default as guidelinesbPpiue7BkZMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/guidelines.vue?macro=true";
import { default as deleteaccountRSPQilT2Q0Meta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/deleteaccount.vue?macro=true";
import { default as indexS328WL2BRZMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/index.vue?macro=true";
import { default as privacyxhh3L1p53cMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/privacy.vue?macro=true";
import { default as privacykeycloakKZE45K5Z0vMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/privacykeycloak.vue?macro=true";
import { default as termsNWdWaZBVFMMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/terms.vue?macro=true";
import { default as termskeycloak69JFNyM7llMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/termskeycloak.vue?macro=true";
import { default as indexx7MBmga3MaMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/index.vue?macro=true";
import { default as loginXM1ITFaT4kMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/login.vue?macro=true";
import { default as logoutDXmPoMKBkvMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/logout.vue?macro=true";
import { default as map0sLzwyy82DMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/map.vue?macro=true";
import { default as indexzRUbBVJY2eMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/news/[slug]/index.vue?macro=true";
import { default as index9gQOxGPIf0Meta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/news/index.vue?macro=true";
import { default as newsletterZtDMAYivhGMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/newsletter.vue?macro=true";
import { default as partnerpgG2z4A4PWMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/partner.vue?macro=true";
import { default as _91name_93wsATMP2HFwMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/partners/[name].vue?macro=true";
import { default as iframeABgbAKOo6TMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/premium/iframe.vue?macro=true";
import { default as indexs74Uv6sfQKMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/premium/index.vue?macro=true";
import { default as profilenpSn8wyPL5Meta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/profile.vue?macro=true";
import { default as settingsQQgLuMnPclMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/settings.vue?macro=true";
import { default as editiiNNPsX94gMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/spots/[id]/edit.vue?macro=true";
import { default as indexJs0QXrlyxXMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/spots/[id]/index.vue?macro=true";
import { default as indexUoeNr0mpcbMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/spots/index.vue?macro=true";
import { default as stylesr38Ma307AlMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/styles.vue?macro=true";
import { default as _91name_93roG3S7ZwUqMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/town/[name].vue?macro=true";
import { default as _91nameOrOldId_93uYFd6h0d5ZMeta } from "/home/runner/work/moveable-frontend/moveable-frontend/pages/users/[nameOrOldId].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/about.vue")
  },
  {
    name: "addSpot",
    path: "/addSpot",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/addSpot.vue")
  },
  {
    name: "entries-oldId",
    path: "/entries/:oldId()",
    meta: index8PDrEz3orJMeta || {},
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/entries/[oldId]/index.vue")
  },
  {
    name: "entries",
    path: "/entries",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/entries/index.vue")
  },
  {
    name: "guidelines",
    path: "/guidelines",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/guidelines.vue")
  },
  {
    name: "imprint-deleteaccount",
    path: "/imprint/deleteaccount",
    meta: deleteaccountRSPQilT2Q0Meta || {},
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/deleteaccount.vue")
  },
  {
    name: "imprint",
    path: "/imprint",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/index.vue")
  },
  {
    name: "imprint-privacy",
    path: "/imprint/privacy",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/privacy.vue")
  },
  {
    name: "imprint-privacykeycloak",
    path: "/imprint/privacykeycloak",
    meta: privacykeycloakKZE45K5Z0vMeta || {},
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/privacykeycloak.vue")
  },
  {
    name: "imprint-terms",
    path: "/imprint/terms",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/terms.vue")
  },
  {
    name: "imprint-termskeycloak",
    path: "/imprint/termskeycloak",
    meta: termskeycloak69JFNyM7llMeta || {},
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/imprint/termskeycloak.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexx7MBmga3MaMeta || {},
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/logout.vue")
  },
  {
    name: "map",
    path: "/map",
    meta: map0sLzwyy82DMeta || {},
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/map.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/news/[slug]/index.vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/news/index.vue")
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/newsletter.vue")
  },
  {
    name: "partner",
    path: "/partner",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/partner.vue")
  },
  {
    name: "partners-name",
    path: "/partners/:name()",
    meta: _91name_93wsATMP2HFwMeta || {},
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/partners/[name].vue")
  },
  {
    name: "premium-iframe",
    path: "/premium/iframe",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/premium/iframe.vue")
  },
  {
    name: "premium",
    path: "/premium",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/premium/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/profile.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/settings.vue")
  },
  {
    name: "spots-id-edit",
    path: "/spots/:id()/edit",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/spots/[id]/edit.vue")
  },
  {
    name: "spots-id",
    path: "/spots/:id()",
    meta: indexJs0QXrlyxXMeta || {},
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/spots/[id]/index.vue")
  },
  {
    name: "spots",
    path: "/spots",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/spots/index.vue")
  },
  {
    name: "styles",
    path: "/styles",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/styles.vue")
  },
  {
    name: "town-name",
    path: "/town/:name()",
    meta: _91name_93roG3S7ZwUqMeta || {},
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/town/[name].vue")
  },
  {
    name: "users-nameOrOldId",
    path: "/users/:nameOrOldId()",
    component: () => import("/home/runner/work/moveable-frontend/moveable-frontend/pages/users/[nameOrOldId].vue")
  }
]