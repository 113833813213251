import Keycloak, {
  type KeycloakLoginOptions,
  type KeycloakLogoutOptions,
  type KeycloakRegisterOptions
} from 'keycloak-js';

export const useAuth = () => {
  const { $keycloak } = useNuxtApp();
  const keycloak = $keycloak as Keycloak;
  const token = useCookie<string>('token');
  const authenticated = useCookie<boolean>('authenticated');
  const user = useCookie<User | undefined>('user');
  const keycloakId = useCookie<string>('keycloakId');
  const showOnboarding = useLocalState<boolean>('showOnboarding', () => false);
  const platformSettings = usePlatformSettings();

  const updateLocale = (locale: string) => {
    const formData = new FormData();
    formData.append('locale', locale);
    useApiAuth().post<User>('users/current/locale', formData).then((result) => {
      if (result) {
        user.value = result;
      }
    });
  };

  const setUserProfileImageId = (imageId: number) => {
    if (user.value !== undefined) {
      user.value!.profileImageId = imageId;
    }
  };

  const setUserBio = (bio: string) => {
    if (user.value !== undefined) {
      user.value!.bio = bio;
    }
  };

  const getUser = (token: string) => {
    // Providing token as a parameter is a workaround
    // At the moment of the call, auth.token.value is not set yet, for whatever reason
    useApi().getWithToken<User>('users/current/login', token).then((response) => {
      user.value = response;
      showOnboarding.value = response.firstLogin === null;
      platformSettings.setLocale(response.locale);
    });
  };

  const refreshToken = () => {
    return keycloak.updateToken();
  }

  const setAuthenticated = (theAuthenticated: boolean) => {
    authenticated.value = theAuthenticated;
  };

  const setKeycloakId = (theKeycloakId: string) => {
    keycloakId.value = theKeycloakId;
  };

  const setToken = (theToken: string) => {
    token.value = theToken;
  };

  const setShowOnboarding = (theShowOnboarding: boolean) => {
    showOnboarding.value = theShowOnboarding;
  };

  const accountManagement = () => {
    return keycloak.accountManagement();
  };


  const login = (options?: KeycloakLoginOptions): Promise<void> => {
    return keycloak.login({ locale: usePlatformSettings().locale.value, ...options });
  };

  const register = (options?: KeycloakRegisterOptions): Promise<void> => {
    return keycloak.register(options);
  };

  const setLoggedOut = () => {
    token.value = '';
    authenticated.value = false;
    user.value = undefined;
    keycloakId.value = '';
  };

  const logout = (options?: KeycloakLogoutOptions): Promise<void> => {
    setLoggedOut();
    return keycloak.logout(options);
  };


  return {
    updateLocale,
    setUserProfileImageId,
    setUserBio,
    getUser,
    refreshToken,
    authenticated,
    keycloak,
    setAuthenticated,
    showOnboarding,
    setShowOnboarding,
    keycloakId,
    setKeycloakId,
    token,
    setToken,
    user,
    accountManagement,
    login,
    register,
    setLoggedOut,
    logout
  };
};
