import * as Sentry from "@sentry/vue";

async function lazyLoadSentryIntegrations() {
  // don't load on server
  if (!process.client) return;

  Sentry.addIntegration(Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true }));

}

function getSentryIntegrations() {
  // don't load on server
  if (!process.client) return [];

  const router = useRouter();

  const browserTracing = Sentry.browserTracingIntegration({ router });
  return [browserTracing];
}


export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;

    const config = useRuntimeConfig();

    Sentry.init({
      app: vueApp,
      dsn: config.public.SENTRY_DSN_PUBLIC ?? null,
      integrations: getSentryIntegrations(),
      environment: config.public.SENTRY_PROFILE as string,
      enabled: config.public.SENTRY_PROFILE !== "local",

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [useRuntimeConfig().public.API_BASE_URL as string],

      // This sets the sample rate. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0
    });

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations();

  }
});
