import type { RouterOptions } from '@nuxt/schema';

export default <RouterOptions>{
  scrollBehavior(to, from, savedPosition) {
    const nuxtApp = useNuxtApp()
    const fromSpotDetailToSpotList = from.name == 'spots-id' && (to.name === 'spots' || to.name === 'index' || to.name === 'users-nameOrOldId' || to.name === 'profile' || to.name === 'premium');
    const fromSpotListToSpotDetail = (from.name === 'spots' || from.name === 'index' || from.name === 'users-nameOrOldId' || from.name === 'profile' || from.name === 'premium') && to.name == 'spots-id';

    const fromNewsDetailToNewsList = from.name == 'news-slug' && (to.name === 'index' || to.name === 'news');
    const fromNewsListToNewsDetail = (from.name === 'index' || from.name === 'news') && to.name == 'news-slug';

    if (fromSpotDetailToSpotList || fromNewsDetailToNewsList) {
      if (savedPosition) {
        return new Promise(async (resolve) => {
          nuxtApp.hooks.hookOnce('page:finish', async () => {
            await nextTick();
            resolve(savedPosition);
          });
        });
      }
    } else if (fromSpotListToSpotDetail || fromNewsListToNewsDetail) {
      return new Promise(async (resolve) => {
        nuxtApp.hooks.hookOnce('page:finish', async () => {
          await nextTick();
          resolve({ top: 0 });
        });
      });
    }

    if (!(to.name === 'spots-id' && to.hash)) {
      return new Promise(async (resolve) => {
        nuxtApp.hooks.hookOnce('page:finish', async () => {
          await nextTick();
          resolve({ top: 0 });
        });
      });
    }


  }
};

