import Keycloak, { type KeycloakConfig } from 'keycloak-js';

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const auth = useAuth();
  const router = useRouter();
  const route = useRoute();
  const platformSettings = usePlatformSettings();

  const url = runtimeConfig.public.AUTH_URL as string;
  const realm = runtimeConfig.public.AUTH_REALM as string;
  // We're using the walkable client id as default, to simplify the checks further down
  let clientId = runtimeConfig.public.WALKABLE_AUTH_CLIENT_ID as string;

  usePlatformSettings().setup();

  if (platformSettings.platformName.value == 'bikeable') {
    if (platformSettings.countryCode.value == 'ch') {
      clientId = runtimeConfig.public.BIKEABLE_CH_AUTH_CLIENT_ID as string;
    } else if (platformSettings.countryCode.value == 'de') {
      clientId = runtimeConfig.public.BIKEABLE_DE_AUTH_CLIENT_ID as string;
    } else if (platformSettings.countryCode.value == 'at') {
      clientId = runtimeConfig.public.BIKEABLE_AT_AUTH_CLIENT_ID as string;
    } else if (platformSettings.countryCode.value == 'us') {
      clientId = runtimeConfig.public.BIKEABLE_US_AUTH_CLIENT_ID as string;
    }
  }

  const keycloakConfig: KeycloakConfig = {
    url: url,
    realm: realm,
    clientId: clientId
  };

  const keycloak: Keycloak = new Keycloak(keycloakConfig);

  keycloak.init({ onLoad: 'check-sso' }).then(() => {
  });

  keycloak.onAuthSuccess = () => {
    if (keycloak.idTokenParsed) {
      if (auth.authenticated.value) {
        auth.setToken(keycloak.token!);
      } else {
        auth.setKeycloakId(keycloak.idTokenParsed.sub!);
        auth.setToken(keycloak.token!);
        auth.setAuthenticated(true);
        // Providing token as a parameter is a workaround
        // At the moment of the call, auth.token.value is not set yet, for whatever reason
        auth.getUser(keycloak.token!);
      }
    }
  };

  keycloak.onAuthRefreshSuccess = () => {
    if (keycloak.idTokenParsed) {
      const auth = useAuth();
      auth.setToken(keycloak.token!);
    }
  };

  keycloak.onTokenExpired = () => {
    keycloak.updateToken(5).catch(function() {
      console.log('Got logged out');
      router.push('/logout?origin=' + route.path);
      auth.setLoggedOut();
    });
  };

  nuxtApp.$keycloak = keycloak as Keycloak;
});
