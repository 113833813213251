export default defineNuxtPlugin((nuxtApp) => {
    const auth = useAuth();
    const platformSettings = usePlatformSettings();
    const baseUrl = useBaseUrl();

    const apiAuth = $fetch.create({
      baseURL: baseUrl.value,
      onRequest({ request, options, error }) {
        const headers = options.headers ||= {};
        const platform = platformSettings.platformName;
        const countryCode = platformSettings.countryCode;
        const locale = platformSettings.locale;

        if (Array.isArray(headers)) {
          if (auth.token.value) {
            headers.push(['Authorization', `Bearer ${auth.token.value}`]);
          }
          headers.push(['Cache-Control', 'no-cache']);
          headers.push(['accept-language', locale.value]);
          headers.push(['moveable-platform', platform.value]);
          headers.push(['moveable-countrycode', countryCode.value]);
        } else if (headers instanceof Headers) {
          if (auth.token.value) {
            headers.set('Authorization', `Bearer ${auth.token.value}`);
          }
          headers.set('Cache-Control', 'no-cache');
          headers.set('accept-language', locale.value);
          headers.set('moveable-platform', platform.value);
          headers.set('moveable-countrycode', countryCode.value);
        } else {
          if (auth.token.value) {
            headers.Authorization = `Bearer ${auth.token.value}`;
          }
          headers['Cache-Control'] = 'no-cache';
          headers['accept-language'] = locale.value;
          headers['moveable-platform'] = platform.value;
          headers['moveable-countrycode'] = countryCode.value;
        }
      },
      async onResponseError({ request, options, response }) {
        if (response.status === 401) {
          if (!auth.authenticated.value) {
            const router = useRouter();
            auth.setLoggedOut();
            await router.push('/login');
          } else {
            await auth.login();
            setTimeout(function (){
              return $fetch(request, options);
            }, 500);
          }
        }
      }
    });

    const api = $fetch.create({
      baseURL: baseUrl.value,
      onRequest({ request, options, error }) {

        const headers = options.headers ||= {};
        const platform = platformSettings.platformName;
        const countryCode = platformSettings.countryCode;
        const locale = platformSettings.locale;

        if (Array.isArray(headers)) {
          headers.push(['Cache-Control', 'no-cache']);
          headers.push(['accept-language', locale.value]);
          headers.push(['moveable-platform', platform.value]);
          headers.push(['moveable-countrycode', countryCode.value]);
        } else if (headers instanceof Headers) {
          headers.set('Cache-Control', 'no-cache');
          headers.set('accept-language', locale.value);
          headers.set('moveable-platform', platform.value);
          headers.set('moveable-countrycode', countryCode.value);
        } else {
          headers['Cache-Control'] = 'no-cache';
          headers['accept-language'] = locale.value;
          headers['moveable-platform'] = platform.value;
          headers['moveable-countrycode'] = countryCode.value;
        }
      }
    });

    return {
      provide: {
        apiAuth,
        api
      }
    };
  }
)
