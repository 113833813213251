import revive_payload_client_4sVQNw7RlN from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/moveable-frontend/moveable-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_5j40ijIZYu from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_1doCGnr5OF from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_keycloak_client_srIFdLFrw7 from "/home/runner/work/moveable-frontend/moveable-frontend/plugins/01.keycloak.client.ts";
import _02_fetchFail_client_Np4GxrMsCN from "/home/runner/work/moveable-frontend/moveable-frontend/plugins/02.fetchFail.client.ts";
import _03_sentry_client_RmhACl5F9Y from "/home/runner/work/moveable-frontend/moveable-frontend/plugins/03.sentry.client.ts";
import _04_api_wkwmf31gNz from "/home/runner/work/moveable-frontend/moveable-frontend/plugins/04.api.ts";
import _05_linkify_1Vwvds99Vz from "/home/runner/work/moveable-frontend/moveable-frontend/plugins/05.linkify.ts";
import plugin_auto_pageviews_client_x3Y8XIaaPY from "/home/runner/work/moveable-frontend/moveable-frontend/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_5j40ijIZYu,
  plugin_1doCGnr5OF,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  _01_keycloak_client_srIFdLFrw7,
  _02_fetchFail_client_Np4GxrMsCN,
  _03_sentry_client_RmhACl5F9Y,
  _04_api_wkwmf31gNz,
  _05_linkify_1Vwvds99Vz,
  plugin_auto_pageviews_client_x3Y8XIaaPY
]