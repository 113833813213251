export const useApiAuth = () => {

  const apiAuth = useNuxtApp().$apiAuth;

  const get = <T>(url: string, query?: any) => {
    if (query) {
      return apiAuth<T>(url, { query: query, server: false });
    } else {
      return apiAuth<T>(url, { server: false });
    }
  };

  const post = <T>(url: string, data?: any, options?: any) => {
    return apiAuth<T>(url, {
      ...options,
      method: 'post',
      server: false,
      body: data
    });
  };

  const put = <T>(url: string, data: any, options?: any) => {

    return apiAuth<T>(url, {
      ...options,
      method: 'put',
      server: false,
      body: data
    });
  };

  const del = <T>(url: string, options?: any) => {

    return apiAuth<T>(url, {
      ...options,
      server: false,
      method: 'delete'
    });
  };

  const asyncData = <T>(url: string | (() => string),
                        query?: any) => {

    return useFetch(url, {
      ...{ query: query },
      server: false,
      $fetch: apiAuth
    });
  };

  return {
    get,
    post,
    put,
    del,
    asyncData
  };

};
