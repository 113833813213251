
export const useApi = () => {

  const api = useNuxtApp().$api;

  const getWithToken = <T>(url: string, token: string, options?: any) => {

    return api<T>(url, {
      ...options,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  };

  const get = <T>(url: string, query?: any) => {
    if (query) {
      return api<T>(url, { query: query });
    } else {
      return api<T>(url);
    }
  };

  const asyncData = <T>(url: string | (() => string),
                    query?: any) => {

    return useFetch(url, {
      ...{ query: query },
      $fetch: api
    });
  };

  return {
    get,
    getWithToken,
    asyncData
  };

};
